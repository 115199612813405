import DatePicker from "../validators/datepicker";
import common from "../utils/common";
import configData from "../../config";
import AgGridThumbnail from '../components/AgGridThumbnail.vue'

async function beforeMount(vm) {
  vm.components.datePicker = DatePicker.getDatePicker();
  
  const lookupTypes = configData.lookupTypes;
  await Promise.all([
    common.fetchLookupData(vm, [lookupTypes.gtmStages, lookupTypes.schedules])
  ]);
    
  vm.columnDefs = getColumnDefs(vm);
  common.postMountColumnDefSetup(vm);
}

function getColumnDefs(vm) {
  return [
    {
      headerName: "",
      field: "checkbox",
      pinned: "left",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: 45,
      cellClassRules: {
        recentRow: function(params) {
          var reqDate = new Date(params.data.modifiedDate);
          var today = new Date();
          today.setDate(today.getDate() - 5);
          if (reqDate > today) {
            return true;
          }
        }
      }
    },

    {
      headerName: "Approve/Reject",
      field: "approval_state",
      editable: true,
      width: 145,
      suppressPaste: true,
      cellEditor: "agRichSelectCellEditor",
      pinned: "left",
      cellEditorParams: function(params) {
        if (params.data.currentState == 1) {
          return { values: ["PULL_BACK"] };
        } else {
          // remove MORE_INFO because transformPayload originally does not include it
          return { values: ["Approve", "Reject", "Drop", /*"MORE_INFO"*/] };
        }
      }
    },

    {
      headerName: "Request ID",
      field: "id",
      pinned: "left",
      width: 120,
      filter: true,
      cellClass: "noneditableCol",
      comparator: function(valueA, valueB) {
        return valueA - valueB;
      }
    },
    // {
    //   headerName: "Parent ID",
    //   field: "parentId",
    //   width: 115,
    //   cellClass: "noneditableCol",
    //   filter: true,
    //   pinned: "left",
    // },

    {
      headerName: "Status",
      field: "lastAction",
      cellClass: "noneditableCol",
      pinned: "left",
      width: 170,
      filter: "agSetColumnFilter",
      valueGetter: function(params) {
        if (params.data.currentState == 1) {
          return "APPROVED";
        } else if (
          params.data.currentState == 0 &&
          params.data.lastAction == "MORE_INFO"
        ) {
          return "MORE_INFO";
        } else if (
          (params.data.currentState == 0 &&
            params.data.lastAction == "NEW") ||
          params.data.lastAction == "PULL_BACK" ||
          params.data.lastAction == "MORE_INFO_RESPONSE"
        ) {
          return "New";
        } else if (
          params.data.currentState == 0 &&
          params.data.lastAction == "REJECT"
        ) {
          return "REJECTED";
        } else {
          // hlin note: current state is 0 (new) and lastAction CAN BE "APPROVE"
        }
      },
      cellClassRules: {
        moreinfo: function(params) {
          if (params.node.data.lastAction == "MORE_INFO") {
            return true;
          }
        }
      }
    },
    {
      headerName: "Thumbnail",
      field: 'productUrl',
      pinned: "left",
      width:130,
      filter: false,
      cellRendererFramework: AgGridThumbnail,
    },
    {
      headerName: "Curr Stage",
      field: "currentState",
      cellClass: "noneditableCol",
      filter: true,
      width: 130,
      pinned: "left",
      hide: true,
    },
    {
      headerName: "More Info Comments",
      field: "moreInfoComments",
      editable: row => row.node.data.lastAction === "MORE_INFO",
      cellClassRules: {
      noneditableCol: row => !(row.node.data.lastAction === "MORE_INFO")
      },
      cellEditorParams: {
        maxLength: 1000,
    },
      cellEditor: "agLargeTextCellEditor"
    },
    {
      headerName: "Season",
      field: "season",
      width: 120,
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: { values: vm.seasonCodes },
      filterParams: { comparator: common.seasonComparator },
    },
    {
      headerName: "GTM Stage",
      field: "gtmStage",
      width: 145,
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData[configData.lookupTypes.gtmStages])
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
      },
      suppressPaste: true
    },
    {
      headerName: "Comments",
      field: "rlComments",
      width: 145,
      singleClickEdit: true,
      editable: true,
      cellEditorParams: {
        maxLength: 1000,
    },
      cellEditor: "agLargeTextCellEditor"
    },

    {
      headerName: "Brand",
      field: "brand",
      width: 140,
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Clusters",
      field: "requestingRegion",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Requesting Merchant",
      field: "requestingMerchant",
      cellClass: "noneditableCol",
      filter: true
    },
    {
      headerName: "Channel",
      field: "channel",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Biggest Volume Country",
      field: "country",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Reason Code",
      field: "requestReason",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: configData.datafields["requestReasonCodes"]
      }
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Category",
      field: "category",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Price Tier",
      field: "priceTier",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Type Of Request",
      field: "requestType",
      cellClass: "noneditableCol",
      filter: true
    },
    {
      headerName: "Explanation of Request",
      field: "requestExplanation",
      cellClass: "noneditableCol"
    },
    {
      headerName: "PC5/PC9",
      field: "similarPC9",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Product Description",
      field: "description",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Finish",
      field: "finish",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Anticipated Volume",
      field: "anticipatedVolume",
      cellClass: "noneditableCol",
      cellStyle: function(params) {
        if(params.value > 1500 && params.value <= 5000){
          return {  backgroundColor: "yellow"}
        }
      },
    },
    {
      headerName: "Last Season Offered",
      field: "lastSeasonOffered",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Requested On Floor",
      field: "requestOnFloor",
      cellClass: "noneditableCol",
      valueGetter: row => common.dateValueGetter(row.data.requestOnFloor),
      
    }
  ];
}

function updated() {}

function save(vm) {
  common.approveRequest(vm, validation, transformToPayload);
}

function drop(vm) {
  common.dropRequest(vm)
}

function validation() {
  return true;
}

function transformToPayload(rows) {
  const result = [];
  for (let row of rows) {
    const data = row.data;
    result.push({
      id: data.id,
      currentState: data.currentState,
      action: data.action,
      moreInfoComments: data.moreInfoComments,
      comments: data.rlComments,
      rlComments: data.rlComments,
      requestReason: data.requestReason,
    });
  }
  return result;
}

export default {
  beforeMount,
  updated,
  save,
  drop,
  getColumnDefs,
}